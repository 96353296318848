import {
  Town as TownActor,
  Adventurer as AdventurerActor,
} from "../../chronicles";

export default function assignProfession(town) {
  const possibleAdventurers = town.adventurers.filter((adventurer) => {
    return adventurer.getMeta("profession") == null;
  });

  if (possibleAdventurers.length === 0) return;

  if (town.random.bool({ likelihood: 90 }) === true) return;

  const adventurer = town.random.pickone(possibleAdventurers);

  const profession = town.random.pickone([
    "Musician",
    "Poet",
    "Painter",
    "Sculptor",
    "Writer",
    "Miner",
    "Woodworker",
    "Stoneworker",
    "Hunter",
    "Doctor",
    "Farmer",
    "Metalsmith",
    "Jewler",
  ]);

  adventurer.setMeta("profession", profession);

  town.world.addEvents({
    description: `{0} became a ${profession} in {1}`,
    reference: `{2} becoming a ${profession} in {3}`,
    actors: [
      new AdventurerActor({ entity: adventurer }),
      new TownActor({ entity: town }),
    ],
  });
}

import Actor from "./Actor";

export default class Alliance extends Actor {
  constructor(props) {
    super({
      ...props,
      type: "alliance",
    });
  }
}

export default class Title {
  constructor(props) {
    const { name, label, manager, holder, maleTitle, femaleTitle } = props;

    this._manager = manager;

    this._id = this.random.guid();

    this._name = name;
    this._label = label;
    this._holder = holder;
    this._maleTitle = maleTitle;
    this._femaleTitle = femaleTitle;
  }

  get manager() {
    return this._manager;
  }

  get random() {
    return this._manager.random;
  }

  get something() {
    return this._manager.something;
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get label() {
    return this._label;
  }

  get holder() {
    return this._holder;
  }

  get maleTitle() {
    return this._maleTitle;
  }

  get femaleTitle() {
    return this._femaleTitle;
  }

  set holder(holder) {
    if (this._holder != null) this._holder.setMeta("title", null);
    if (holder != null) holder.setMeta("title", this);
    this._holder = holder;
  }
}

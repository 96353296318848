import React from "react";

import Tabs from "./Tabs";
import Actor from "./Actor";
import Dialog from "./Dialog";
import TownNoblesTabContent from "./TownNoblesTabContent";
import TownTitlesTabContent from "./TownTitlesTabContent";
import ActorHistoryTabContent from "./ActorHistoryTabContent";

function TownDialog(props) {
  const { town } = props;

  const tabs = React.useMemo(() => {
    return [
      {
        label: "History",
        content: <ActorHistoryTabContent type="town" actorID={town.id} />,
      },
      {
        label: "Nobles",
        content: <TownNoblesTabContent town={town} />,
      },
      {
        label: "Titles",
        content: <TownTitlesTabContent town={town} />,
      },
    ];
  }, [town]);

  return (
    <Dialog {...props}>
      <header className="DialogHeader">
        <img
          width="50"
          height="50"
          className="TownDialogHeraldry"
          alt={`${town.label} Heraldry`}
          src={`http://armoria.herokuapp.com/png/50/${town.id}?shield=french`}
        />

        <div>
          <h2 className="DialogHeaderTitle">{town.label}</h2>

          <h3 className="DialogHeaderSubTitle">
            <Actor type="culture" actorID={town.entity.culture.id} />
          </h3>
        </div>
      </header>

      <div className="DialogContent">
        <Tabs tabs={tabs} />
      </div>
    </Dialog>
  );
}

export default React.memo(TownDialog);

import Title from "./Title";

export default class TownTitle extends Title {
  constructor(props) {
    super({
      ...props,
      type: "town-title",
    });
  }
}

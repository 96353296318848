import {
  priorities,
  Town as TownActor,
  TownTitle as TownTitleActor,
  Adventurer as AdventurerActor,
} from "../../chronicles";

import { governorTitleInfo } from "./governor-title-creation";

export default function govornor(town) {
  const candidates = town.adventurers.filter(
    (adventurer) =>
      adventurer.getAge(town.world.year) > 18 &&
      adventurer.getMeta("title") == null
  );

  if (candidates.length === 0) return;

  const governorTitle = town.titles.get(governorTitleInfo.name);
  if (governorTitle == null) return;

  if (governorTitle.holder == null) {
    if (town.random.bool({ likelihood: 40 }) === true) return;

    governorTitle.holder = town.random.pickone(candidates);

    town.world.addEvents({
      description: "{0} is appointed {1} of {2}",
      reference: "the appointment of {2} as the {3} of {4}",
      actors: [
        new AdventurerActor({
          entity: governorTitle.holder,
          importance: priorities.HIGH,
        }),
        new TownTitleActor({ entity: governorTitle }),
        new TownActor({ entity: town }),
      ],
    });

    return;
  } else if (governorTitle.holder.isAlive(town.world.year) === false) {
    town.titles.all.forEach((title) => {
      const currentHolder = title.holder;

      if (currentHolder == null) return;
      title.holder = null;

      town.world.addEvents({
        description: "{0} has stepped down from {1} of {2}",
        reference: "the stepping down of {2} as the {3} of {4}",
        actors: [
          new AdventurerActor({
            entity: currentHolder,
            importance: priorities.HIGH,
          }),
          new TownTitleActor({ entity: title }),
          new TownActor({ entity: town }),
        ],
      });
    });

    return;
  }

  const possibleTitles = town.titles.all.filter(
    (title) => title.holder == null
  );

  possibleTitles
    .slice(0, Math.min(4, possibleTitles.length, candidates.length))
    .forEach((possibleTitle) => {
      const adventurer = town.random.pickone(candidates);
      possibleTitle.holder = adventurer;

      candidates.splice(candidates.indexOf(adventurer), 1);

      town.world.addEvents({
        description: "{0} is appointed {1} of {2}",
        reference: "the appointment of {2} as the {3} of {4}",
        actors: [
          new AdventurerActor({
            entity: possibleTitle.holder,
            importance: priorities.HIGH,
          }),
          new TownTitleActor({ entity: possibleTitle }),
          new TownActor({ entity: town }),
        ],
      });
    });
}

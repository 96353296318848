const tileTypes = {
  ROAD: "ROAD",
  TOWN: "TOWN",

  MOUNTAIN_ICE: "MOUNTAIN_ICE",
  MOUNTAIN: "MOUNTAIN",

  HILL_ICE: "HILL_ICE",
  HILL: "HILL",
  HILL_SOIL: "HILL_SOIL",
  HILL_SWAMP: "HILL_SWAMP",
  HILL_GRASS: "HILL_GRASS",
  HILL_SAND: "HILL_SAND",

  PLAIN_ICE: "PLAIN_ICE",
  PLAIN_SWAMP: "PLAIN_SWAMP",
  PLAIN_FOREST: "PLAIN_FOREST",
  PLAIN_GRASS: "PLAIN_GRASS",
  PLAIN_SAND: "PLAIN_SAND",
  PLAIN_MARSH: "PLAIN_MARSH",
  PLAIN_SOIL: "PLAIN_SOIL",
  PLAIN: "PLAIN",

  WATER: "WATER",
  WATER_ICE: "WATER_ICE",

  OCEAN: "OCEAN",
  OCEAN_ICE: "OCEAN_ICE",
};

export default tileTypes

import React from "react";

import Footer from "./Footer";
import Simulation from "./Simulation";
import SettingsDialog from "./SettingsDialog";

import "./styles.css";

function App() {
  const [info, setInfo] = React.useState();

  const reset = React.useCallback(() => {
    setInfo(null);
  }, []);

  return (
    <>
      {info == null ? (
        <SettingsDialog onSubmit={setInfo} />
      ) : (
        <Simulation
          onReset={reset}
          seed={info.seed}
          width={info.width}
          height={info.height}
        />
      )}
      <Footer />
    </>
  );
}

export default React.memo(App);

import { Name } from "name-df-generator";

export default class Road {
  constructor(props) {
    const { world, tiles } = props;

    this._world = world;
    this._tiles = tiles;
    this._id = this.random.guid();
    this._name = new Name({ seed: this._id });

    this._type = this.random.pickone([
      "Road",
      "Street",
      "Way",
      "Avenue",
      "Lane",
      "Grove",
    ]);

    this._tiles.forEach((tile) => tile.addFeature(this));
  }

  get world() {
    return this._world;
  }

  get random() {
    return this._world.random;
  }

  get id() {
    return this._id;
  }

  get name() {
    return `${this._name} ${this._type}`;
  }

  get tiles() {
    return [...this._tiles];
  }
}

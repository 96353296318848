export default class ChronicleEvent {
  constructor(props) {
    const { year, actors, chronicle, reference, description } = props;

    this._chronicle = chronicle;
    this._id = this.random.guid();

    this._year = year;
    this._reference = reference;
    this._description = description;
    this._actors = this._chronicle.registerActors(...actors);

    this._actors.forEach((actor) => actor.add(this));
  }

  get chronicle() {
    return this._chronicle;
  }

  get random() {
    return this._chronicle.random;
  }

  get id() {
    return this._id;
  }

  get year() {
    return this._year;
  }

  get actors() {
    return this._actors;
  }

  get reference() {
    return this._reference;
  }

  get description() {
    return this._description;
  }

  toString() {
    return this._actors.reduce((description, actor, index) => {
      return description.replaceAll(`{${index}}`, actor.label);
    }, this._description);
  }
}

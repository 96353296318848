import React from "react";
import Actor from "./Actor";

function CultureTownsTabContent(props) {
  const { culture: cultureProp } = props;

  const culture = React.useMemo(() => {
    return cultureProp.entity;
  }, [cultureProp]);

  return React.useMemo(() => {
    const towns = culture.towns;

    return towns.length === 0 ? (
      <center>N/A</center>
    ) : (
      <ul>
        {towns.map((town) => {
          return (
            <li key={`town-${town.id}`}>
              <Actor type="town" actorID={town.id} />
            </li>
          );
        })}
      </ul>
    );
  }, [culture]);
}

export default React.memo(CultureTownsTabContent);

import React from "react";
import { sentenceCase } from "change-case"
import { ToWords } from "to-words";

import { genders } from "culture-generator";

import Actor from "./Actor";
import AppContext from "./AppContext";

function AdventurerFamilyTabContent(props) {
  const { adventurer: adventurerProp } = props;

  const { world } = React.useContext(AppContext);

  const toWords = React.useMemo(() => {
    return new ToWords();
  }, []);

  const adventurer = React.useMemo(() => {
    return adventurerProp.entity;
  }, [adventurerProp]);

  const isDeath = React.useMemo(() => {
    return adventurer.isAlive(world.year) === false
  }, [world, adventurer])

  const dob = React.useMemo(() => {
    const pronoun = adventurer.gender === genders.MALE ? "He" : "She";

    const age = toWords.convert(
      adventurer.getAge(
        isDeath === true ? adventurer.death : world.year
      )
    ).toLowerCase();

    if (isDeath === true) {
     return (
      <p>
        {pronoun} died at the age of {age} and he was from the {" "}
        <Actor type="culture" actorID={adventurer.culture.id} /> civilization.
      </p>
     )
    }

    return (
      <p>
        {pronoun} is {age} years old, born on in {world.year} and is from the{" "}
        <Actor type="culture" actorID={adventurer.culture.id} /> civilization.
      </p>
    );
  }, [adventurer, toWords, world, isDeath]);

  const parentsDOM = React.useMemo(() => {
    if (adventurer.parents.length === 0) return;

    const pronoun = adventurer.gender === genders.MALE ? "He is" : "She is";

    const son = adventurer.gender === genders.MALE ? "son" : "daughter";

    return (
      <p>
        {pronoun} the {son} of{" "}
        <Actor type="adventurer" actorID={adventurer.father.id} /> and{" "}
        <Actor type="adventurer" actorID={adventurer.mother.id} />.
      </p>
    );
  }, [adventurer]);

  const spouseDOM = React.useMemo(() => {
    const pronoun = adventurer.gender === genders.MALE ? "He is" : "She is";

    const spouseDOM = [];

    if (adventurer.spouse != null) {
      spouseDOM.push(
        <span>
          {pronoun} married to{" "}
          <Actor type="adventurer" actorID={adventurer.spouse.id} />
        </span>
      );
    }

    const children = adventurer.children.map((adventurer) => {
      return <Actor type="adventurer" actorID={adventurer.id} />;
    });

    if (children.length > 0) {
      spouseDOM.push(
        <span>
          {" "}
          and has {toWords
            .convert(adventurer.children.length)
            .toLowerCase()}{" "}
          {children.length === 1 ? "child" : "children"}
        </span>
      );
    }

    return spouseDOM.length === 0 ? null : <p>{spouseDOM}.</p>;
  }, [adventurer, toWords]);

  const bodyAttributesDOM = React.useMemo(() => {
    return (
      adventurer.bodyAttributes != null && (
        <p>{
          adventurer.bodyAttributes.split(". ").map(
            sentenceCase
          ).join(". ")
        }.</p>
      )
    );
  }, [adventurer]);

  const soulAttributesDOM = React.useMemo(() => {
    return (
      adventurer.soulAttributes != null && (
        <p>{adventurer.soulAttributes.split(". ").map(
          sentenceCase
        ).join(". ")}.</p>
      )
    );
  }, [adventurer]);

  const otherDetailsDOM = React.useMemo(() => {
    const pronoun = adventurer.gender === genders.MALE ? "He" : "She";

    if (isDeath === true) return

    return (
      <p>
        {
          adventurer.getMeta("profession") == null ? (
            <>
              {pronoun} lives in <Actor type="town" actorID={adventurer.getMeta("location").id} />
            </>
          ) : (
            <>
              {pronoun} is a ${adventurer.getMeta("profession")} in {" "}
              <Actor type="town" actorID={adventurer.getMeta("location").id} />
            </>
          )
        }
      </p>
    );
  }, [isDeath, adventurer]);

  return (
    <>
      {dob}
      {parentsDOM}
      {spouseDOM}
      {otherDetailsDOM}

      <p>{
        adventurer.appearance.split(". ").map(
          sentenceCase
        ).join(". ")
      }.</p>

      {bodyAttributesDOM}
      {soulAttributesDOM}

      <p>{
        adventurer.facets.split(". ").map(
          sentenceCase
        ).join(". ")
      }.</p>
    </>
  );
}

export default React.memo(AdventurerFamilyTabContent);

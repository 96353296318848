import React from "react";

import AppContext from "./AppContext";

import "./Actor.css";

function Actor(props) {
  const { type, actorID } = props;

  const { world, selectedActorInfo, setSelectedActorInfo } =
    React.useContext(AppContext);

  const actor = React.useMemo(() => {
    return world.chronicles.getActor(type, actorID);
  }, [type, world, actorID]);

  const onClick = React.useCallback(() => {
    setSelectedActorInfo({
      type,
      entity: actor,
    });
  }, [type, actor, setSelectedActorInfo]);

  const classes = React.useMemo(() => {
    return type !== selectedActorInfo.type ||
      actorID !== selectedActorInfo?.entity.id
      ? "Actor"
      : null;
  }, [type, actorID, selectedActorInfo]);

  return (
    <span onClick={onClick} className={classes}>
      {actor.label}
    </span>
  );
}

export default React.memo(Actor);

import {
  priorities,
  Town as TownActor,
  TownTitle as TownTitleActor,
} from "../../chronicles";

export const governorTitleInfo = {
  name: "governor",
  label: "Governor",
  maleTitle: "Governor",
  femaleTitle: "Governor",
};

const titlesInfo = [
  {
    name: "almoner",
    label: "Almoner",
    maleTitle: "Almoner",
    femaleTitle: "Almoner",
  },
  {
    name: "butler",
    label: "Butler",
    maleTitle: "Butler",
    femaleTitle: "Butler",
  },
  {
    name: "chamberlain",
    label: "Chamberlain",
    maleTitle: "Chamberlain",
    femaleTitle: "Chamberlain",
  },
  {
    name: "chancellor",
    label: "Chancellor",
    maleTitle: "Chancellor",
    femaleTitle: "Chancellor",
  },
  {
    name: "chapelmaster",
    label: "Chapelmaster",
    maleTitle: "Chapelmaster",
    femaleTitle: "Chapelmaster",
  },
  {
    name: "cofferer",
    label: "Cofferer",
    maleTitle: "Cofferer",
    femaleTitle: "Cofferer",
  },
  {
    name: "constable",
    label: "Constable",
    maleTitle: "Constable",
    femaleTitle: "Constable",
  },
  {
    name: "cup-bearer",
    label: "Cup Bearer",
    maleTitle: "Cup Bearer",
    femaleTitle: "Cup Bearer",
  },
  {
    name: "dapifer",
    label: "Dapifer",
    maleTitle: "Dapifer",
    femaleTitle: "Dapifer",
  },
  {
    name: "doorward",
    label: "Doorward",
    maleTitle: "Doorward",
    femaleTitle: "Doorward",
  },
  {
    name: "falconer",
    label: "Falconer",
    maleTitle: "Falconer",
    femaleTitle: "Falconer",
  },
  {
    name: "grandmaster",
    label: "Grandmaster",
    maleTitle: "Grandmaster",
    femaleTitle: "Grandmaster",
  },
  {
    name: "great-officers",
    label: "Great Officers",
    maleTitle: "Great Officers",
    femaleTitle: "Great Officers",
  },
  {
    name: "groom-of-the-stool",
    label: "Groom Of The Stool",
    maleTitle: "Groom Of The Stool",
    femaleTitle: "Groom Of The Stool",
  },
  {
    name: "herald",
    label: "Herald",
    maleTitle: "Herald",
    femaleTitle: "Herald",
  },
  {
    name: "intendant",
    label: "Intendant",
    maleTitle: "Intendant",
    femaleTitle: "Intendant",
  },
  {
    name: "royal-fool",
    label: "Royal Fool",
    maleTitle: "Royal Fool",
    femaleTitle: "Royal Fool",
  },
  {
    name: "keeper-of-the-seal",
    label: "Keeper Of The Seal",
    maleTitle: "Keeper Of The Seal",
    femaleTitle: "Keeper Of The Seal",
  },
  {
    name: "king-of-arms",
    label: "King Of Arms",
    maleTitle: "King Of Arms",
    femaleTitle: "King Of Arms",
  },
  {
    name: "knight-earl-marshal",
    label: "Knight Earl Marshal",
    maleTitle: "Knight Earl Marshal",
    femaleTitle: "Knight Earl Marshal",
  },
  {
    name: "majordomo",
    label: "Majordomo",
    maleTitle: "Majordomo",
    femaleTitle: "Majordomo",
  },
  {
    name: "master-of-ceremonies",
    label: "Master Of Ceremonies",
    maleTitle: "Master Of Ceremonies",
    femaleTitle: "Master Of Ceremonies",
  },
  {
    name: "master-of-the-horse",
    label: "Master Of The Horse",
    maleTitle: "Master Of The Horse",
    femaleTitle: "Master Of The Horse",
  },
  {
    name: "master-of-the-hunt",
    label: "Master Of The Hunt",
    maleTitle: "Master Of The Hunt",
    femaleTitle: "Master Of The Hunt",
  },
  {
    name: "page",
    label: "Page",
    maleTitle: "Page",
    femaleTitle: "Page",
  },
  {
    name: "secretary",
    label: "Secretary",
    maleTitle: "Secretary",
    femaleTitle: "Secretary",
  },
  {
    name: "pursuivant",
    label: "Pursuivant",
    maleTitle: "Pursuivant",
    femaleTitle: "Pursuivant",
  },
  {
    name: "seneschal",
    label: "Seneschal",
    maleTitle: "Seneschal",
    femaleTitle: "Seneschal",
  },
  {
    name: "stolnik",
    label: "Stolnik",
    maleTitle: "Stolnik",
    femaleTitle: "Stolnik",
  },
  {
    name: "standard-bearer",
    label: "Standard-Bearer",
    maleTitle: "Standard-Bearer",
    femaleTitle: "Standard Bearer",
  },
  {
    name: "steward",
    label: "Steward",
    maleTitle: "Steward",
    femaleTitle: "Steward",
  },
];

export default function governorCreation(town) {
  const possibleTitlesInfo = titlesInfo.filter(
    (title) => town.titles.get(title.name) == null
  );

  if (
    town.titles.get(governorTitleInfo.name) == null &&
    town.random.bool({ likelihood: 40 })
  ) {
    possibleTitlesInfo.push(governorTitleInfo);
  }

  possibleTitlesInfo
    .slice(
      0,
      town.random.integer({
        min: 0,
        max: Math.min(4, possibleTitlesInfo.length),
      })
    )
    .forEach((titleInfo) => {
      const [title] = town.titles.create(titleInfo);

      town.world.addEvents({
        description: "{0} people have created the {1} title",
        reference: "creation of {3} title by the {2} people",
        actors: [
          new TownActor({ entity: town }),
          new TownTitleActor({ entity: title, importance: priorities.MEDIUM }),
        ],
      });
    });
}

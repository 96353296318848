import React from "react";

import Tabs from "./Tabs";
import Dialog from "./Dialog";
import ActorHistoryTabContent from "./ActorHistoryTabContent";
import AdventurerInfoTabContent from "./AdventurerInfoTabContent";
import AdventurerFamilyTabContent from "./AdventurerFamilyTabContent";

function AdventurerDialog(props) {
  const { adventurer } = props;

  const tabs = React.useMemo(() => {
    return [
      {
        label: "Info",
        content: <AdventurerInfoTabContent adventurer={adventurer} />,
      },
      {
        label: "History",
        content: (
          <ActorHistoryTabContent type="adventurer" actorID={adventurer.id} />
        ),
      },
      {
        label: "Family",
        content: <AdventurerFamilyTabContent adventurer={adventurer} />,
      },
    ];
  }, [adventurer]);

  return (
    <Dialog {...props}>
      <header className="DialogHeader">
        <h2 className="DialogHeaderTitle">{adventurer.label}</h2>
      </header>

      <div className="DialogContent">
        <Tabs tabs={tabs} />
      </div>
    </Dialog>
  );
}

export default React.memo(AdventurerDialog);

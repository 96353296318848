import { Culture as CultureGeneratorCulture } from "culture-generator";

import Town from "./Town";
import { TitleManager } from "./titles";

import townCreationStory from "./stories/culture/town-creation";
import newSettlementStory from "./stories/culture/new-settlement";
import monarchAppointmentStory from "./stories/culture/monarch-appointment";
import monarchTitleCreationStory from "./stories/culture/monarch-title-creation";

export default class Culture extends CultureGeneratorCulture {
  constructor(props) {
    super({
      seed: props.world.random.guid(),
      ...props,
    });

    const { world } = props;

    this._towns = [];
    this._world = world;

    this._titles = new TitleManager({ world, something: this });
  }

  get world() {
    return this._world;
  }

  get random() {
    return this.world.random;
  }

  get towns() {
    return this._towns;
  }

  get alliances() {
    return this._world.alliances.filter((alliance) => {
      return alliance.cultures.includes(this);
    });
  }

  get allies() {
    return this.alliances.reduce((allies, alliance) => {
      if (alliance.isActive === false) return allies;

      allies.push(
        alliance.cultureOne === this ? alliance.cultureTwo : alliance.cultureOne
      );

      return allies;
    }, []);
  }

  get adventurers() {
    return this._towns.reduce((adventurers, town) => {
      adventurers.push(...town.adventurers);
      return adventurers;
    }, []);
  }

  get titles() {
    return this._titles;
  }

  createTowns(...townsInfo) {
    const newTowns = townsInfo.map((townInfo = {}) => {
      const tile =
        townInfo.tile != null
          ? townInfo.tile
          : this._world.tiles.getHabitableTile();

      const newTown = new Town({
        ...townInfo,
        tile,
        culture: this,
      });

      return newTown;
    });

    this._towns.push(...newTowns);

    return newTowns;
  }

  get nearByCultures() {
    return [
      ...new Set(
        this._towns.reduce((cultures, town) => {
          cultures.push(
            ...town.tile.nearBy({
              distance: 5,
              ignore: [this],
              types: ["CULTURE"],
            })
          );

          return cultures;
        }, [])
      ),
    ];
  }

  tick() {
    monarchAppointmentStory(this);
    monarchTitleCreationStory(this);
    townCreationStory(this);
    newSettlementStory(this);
    this._towns.forEach((town) => {
      town.tick();
    });
  }
}

import color from "color";
import { Name } from "name-df-generator";

import Town from "../Town";
import Road from "../Road";
import tileTypes from "./tileTypes";

export default class Tile {
  constructor(props) {
    const {
      x,
      y,
      manager,
      sand = 0,
      rainfall = 0,
      elevation = 0,
      vegetation = 0,
      temperature = 0,
    } = props;

    this._x = x;
    this._y = y;
    this._weight = null;

    this._sand = sand;
    this._manager = manager;
    this._rainfall = rainfall;
    this._elevation = elevation;
    this._vegetation = vegetation;
    this._temperature = temperature;

    this._name = new Name({ seed: this.random.guid() });

    this._features = [];
  }

  get manager() {
    return this._manager;
  }

  get random() {
    return this._manager.random;
  }

  get id() {
    return `${this.x}-${this.y}`;
  }

  get name() {
    return this._name;
  }

  get x() {
    return this._x;
  }

  get y() {
    return this._y;
  }

  get sand() {
    return this._sand;
  }

  get rainfall() {
    return this._rainfall;
  }

  get elevation() {
    return this._elevation;
  }

  get vegetation() {
    return this._vegetation;
  }

  get temperature() {
    return this._temperature;
  }

  get weight() {
    if (this._weight != null) return this._weight;

    this._weight =
      this.type === tileTypes.MOUNTAIN_ICE
        ? 0.9
        : this.type === tileTypes.MOUNTAIN
        ? 0.9
        : this.type === tileTypes.HILL_ICE
        ? 0.7 + this.random.floating({ min: 0, max: 0.2 })
        : this.type === tileTypes.HILL
        ? 0.5 + this.random.floating({ min: 0, max: 0.4 })
        : this.type === tileTypes.HILL_SWAMP
        ? 0.7 + this.random.floating({ min: 0, max: 0.2 })
        : this.type === tileTypes.HILL_GRASS
        ? 0.6 + this.random.floating({ min: 0, max: 0.3 })
        : this.type === tileTypes.HILL_SOIL
        ? 0.6 + this.random.floating({ min: 0, max: 0.3 })
        : this.type === tileTypes.HILL_SAND
        ? 0.8 + this.random.floating({ min: 0, max: 0.1 })
        : this.type === tileTypes.PLAIN_ICE
        ? 0.4 + this.random.floating({ min: 0, max: 0.5 })
        : this.type === tileTypes.PLAIN_SWAMP
        ? 0.6 + this.random.floating({ min: 0, max: 0.3 })
        : this.type === tileTypes.PLAIN_FOREST
        ? 0.5 + this.random.floating({ min: 0, max: 0.4 })
        : this.type === tileTypes.PLAIN_GRASS
        ? 0.4 + this.random.floating({ min: 0, max: 0.5 })
        : this.type === tileTypes.PLAIN_SOIL
        ? 0.4 + this.random.floating({ min: 0, max: 0.5 })
        : this.type === tileTypes.PLAIN_SAND
        ? 0.6 + this.random.floating({ min: 0, max: 0.3 })
        : this.type === tileTypes.PLAIN_MARSH
        ? 0.6 + this.random.floating({ min: 0, max: 0.3 })
        : this.type === tileTypes.PLAIN
        ? 0.4 + this.random.floating({ min: 0, max: 0.5 })
        : this.type === tileTypes.WATER
        ? 1
        : this.type === tileTypes.WATER_ICE
        ? 0.9
        : this.type === tileTypes.OCEAN
        ? 1
        : this.type === tileTypes.OCEAN_ICE
        ? 0.9
        : 1;

    return this._weight;
  }

  get town() {
    return this._features.find((feature) => feature instanceof Town);
  }

  get road() {
    return this._features.find((feature) => feature instanceof Road);
  }

  get isTown() {
    return this.town != null;
  }

  get isRoad() {
    return this.road != null;
  }

  get type() {
    if (this._elevation > 0.9) {
      return this._temperature < 0.4 && this._rainfall > 0.6
        ? tileTypes.MOUNTAIN_ICE
        : tileTypes.MOUNTAIN;
    }

    if (this._elevation > 0.8) {
      if (this._temperature < 0.4) {
        return this._rainfall > 0.6 ? tileTypes.HILL_ICE : tileTypes.HILL;
      }

      if (this._rainfall > 0.8) {
        return tileTypes.HILL_SWAMP;
      }

      if (this._rainfall > 0.4) {
        return this.vegetation > 0.6
          ? tileTypes.HILL_GRASS
          : tileTypes.HILL_SOIL;
      }

      return this._sand > 0.7 ? tileTypes.HILL_SAND : tileTypes.HILL;
    }

    if (this._elevation > 0.5) {
      if (this._temperature < 0.4) {
        return this._rainfall > 0.6 ? tileTypes.PLAIN_ICE : tileTypes.PLAIN;
      }

      if (this._rainfall > 0.8) {
        return this.vegetation > 0.8
          ? tileTypes.PLAIN_SWAMP
          : tileTypes.PLAIN_MARSH;
      }

      if (this._rainfall > 0.4) {
        return this.vegetation > 0.8
          ? tileTypes.PLAIN_FOREST
          : this.vegetation > 0.3
          ? tileTypes.PLAIN_GRASS
          : tileTypes.PLAIN_SOIL;
      }

      return this._sand > 0.7 ? tileTypes.PLAIN_SAND : tileTypes.PLAIN;
    }

    if (this._elevation > 0.4) {
      return this._temperature > 0.4 ? tileTypes.WATER : tileTypes.WATER_ICE;
    }

    return this._temperature > 0.4 ? tileTypes.OCEAN : tileTypes.OCEAN_ICE;
  }

  get ui() {
    let info = {};

    switch (this.type) {
      case tileTypes.MOUNTAIN_ICE: {
        info = {
          icon: "⌂",
          color: "#FFFFFF",
          backgroundColor: "#222",
        };
        break;
      }
      case tileTypes.MOUNTAIN: {
        info = {
          icon: "⌂",
          color: "#aaa",
          backgroundColor: "#222",
        };
        break;
      }
      case tileTypes.HILL_ICE: {
        info = {
          icon: "∩",
          color: "#FFFFFF",
          backgroundColor: "#222",
        };
        break;
      }
      case tileTypes.HILL: {
        const backgroundColor = this.random.pickone(["#444", "#333"]);

        info = {
          icon: "∩",
          color: color(backgroundColor).lighten(1.5).hex(),
          backgroundColor: backgroundColor,
        };
        break;
      }
      case tileTypes.HILL_SOIL: {
        const backgroundColor = this.random.pickone(["#1e1a15", "#27221b"]);

        info = {
          icon: "∩",
          color: color(backgroundColor).lighten(1.5).hex(),
          backgroundColor: backgroundColor,
        };
        break;
      }
      case tileTypes.HILL_SWAMP: {
        const backgroundColor = this.random.pickone(["#1b2413", "#192211"]);

        info = {
          icon: "∩",
          color: color(backgroundColor).lighten(1.5).hex(),
          backgroundColor,
        };
        break;
      }
      case tileTypes.HILL_GRASS: {
        const backgroundColor = this.random.pickone(["#2d3a22", "#25301c"]);
        info = {
          icon: "∩",
          color: color(backgroundColor).lighten(1.5).hex(),
          backgroundColor,
        };
        break;
      }
      case tileTypes.HILL_SAND: {
        const backgroundColor = this.random.pickone(["#483c1a", "#392f14"]);

        info = {
          icon: "∩",
          color: color(backgroundColor).lighten(1.5).hex(),
          backgroundColor,
        };
        break;
      }
      case tileTypes.PLAIN_ICE: {
        const backgroundColor = this.random.pickone(["#888", "#999"]);

        info = {
          icon: "≈",
          color: color(backgroundColor).lighten(1.5).hex(),
          backgroundColor,
        };
        break;
      }
      case tileTypes.PLAIN_SWAMP: {
        const backgroundColor = this.random.pickone(["#1b2413", "#192211"]);

        info = {
          icon: this.random.pickone(['"', "⌠"]),
          color: color(backgroundColor).lighten(1.5).hex(),
          backgroundColor,
        };
        break;
      }
      case tileTypes.PLAIN_MARSH: {
        const backgroundColor = this.random.pickone(["#151a0f", "#171f0f"]);

        info = {
          icon: this.random.pickone(['"', "ⁿ"]),
          color: color(backgroundColor).lighten(1.5).hex(),
          backgroundColor,
        };
        break;
      }
      case tileTypes.PLAIN_FOREST: {
        const backgroundColor = this.random.pickone(["#2d3a22", "#25301c"]);

        info = {
          icon: this.random.pickone(["♠", "♣", "↑"]),
          color: color(backgroundColor).lighten(1.5).hex(),
          backgroundColor,
        };
        break;
      }
      case tileTypes.PLAIN_GRASS: {
        const backgroundColor = this.random.pickone(["#2d3a22", "#25301c"]);

        info = {
          icon: this.random.pickone(["ⁿ", "."]),
          color: color(backgroundColor).lighten(1.5).hex(),
          backgroundColor,
        };
        break;
      }
      case tileTypes.PLAIN_SOIL: {
        const backgroundColor = this.random.pickone(["#1e1a15", "#27221b"]);

        info = {
          icon: this.random.pickone(["ⁿ", "."]),
          color: color(backgroundColor).lighten(1.5).hex(),
          backgroundColor,
        };
        break;
      }
      case tileTypes.PLAIN_SAND: {
        const backgroundColor = this.random.pickone(["#483c1a", "#392f14"]);

        info = {
          icon: "≈",
          color: color(backgroundColor).lighten(1.5).hex(),
          backgroundColor,
        };
        break;
      }
      case tileTypes.PLAIN: {
        const backgroundColor = this.random.pickone(["#444", "#555"]);
        info = {
          icon: ".",
          color: color(backgroundColor).lighten(1.5).hex(),
          backgroundColor,
        };
        break;
      }
      case tileTypes.WATER: {
        info = {
          icon: "~",
          color: "#35506f",
          backgroundColor: "#1d2d3f",
        };
        break;
      }
      case tileTypes.WATER_ICE: {
        info = {
          icon: this.random.pickone(["▒", "░"]),
          color: "#35506f",
          backgroundColor: "#1d2d3f",
        };
        break;
      }
      case tileTypes.OCEAN: {
        info = {
          icon: "≈",
          color: "#304177",
          backgroundColor: "#172331",
        };
        break;
      }
      case tileTypes.OCEAN_ICE: {
        info = {
          icon: this.random.pickone(["▒", "░"]),
          color: "#35506f",
          backgroundColor: "#1d2d3f",
        };
        break;
      }
      default: {
        info = {
          icon: this.random.pickone(["X"]),
          color: "#ff00d4",
          backgroundColor: "#ff00d4",
        };
        break;
      }
    }

    if (this.isRoad === true) info.icon = "";

    if (this.isTown === true) {
      info = {
        icon: "◙",
        color: "orange",
        backgroundColor: "#222",
      };
    }

    return info;
  }

  addFeature(feature) {
    if (this._features.includes(feature) === true) return;
    this._features.push(feature);
    if (this._features.length === 1) this._manager.cacheFeature(this);
  }

  removeFeature(feature) {
    const position = this._features.indexOf(feature);
    if (position === -1) return;
    this._features.splice(position, 1);

    if (this._features.length === 0) this._manager.uncacheFeature(this);
  }

  nearBy(info) {
    const { ignore = [], distance = 5, types = ["TOWN", "CULTURE"] } = info;

    let entities = [];

    const fromX = Math.max(0, this.x - distance);
    const fromY = Math.max(0, this.y - distance);
    const toX = Math.min(this.manager.world.width, this.x + distance);
    const toY = Math.min(this.manager.world.height, this.y + distance);

    for (let y = fromY; y < toY; y++) {
      for (let x = fromX; x < toX; x++) {
        if (types.includes("TOWN") === true) {
          const town = this.manager.get(x, y).town;

          if (town != null && ignore.includes(town) === false) {
            entities.push(town);
          }
        }

        if (types.includes("CULTURE") === true) {
          const town = this.manager.get(x, y).town;

          if (town != null && ignore.includes(town.culture) === false) {
            entities.push(town.culture);
          }
        }
      }
    }

    return [...new Set(entities)];
  }

  getDistance(x, y) {
    return Math.sqrt(Math.pow(x - this._x, 2) + Math.pow(y - this._y, 2));
  }
}

import {
  Town as TownActor,
  Adventurer as AdventurerActor,
} from "../../chronicles";

export default function death(town) {
  const deaths = town.adventurers.filter((adventurer) => {
    return adventurer.isAlive(town.world.year) === false;
  });

  town.killAdventurers(...deaths);

  deaths.forEach((adventurer) => {
    town.world.addEvents({
      description: "{0} died in {1}",
      reference: "the death of {2} in {3}",
      actors: [
        new AdventurerActor({ entity: adventurer }),
        new TownActor({ entity: town }),
      ],
    });
  });
}

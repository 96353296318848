import React from "react";

import Tabs from "./Tabs";
import Dialog from "./Dialog";
import CultureInfoTabContent from "./CultureInfoTabContent";
import ActorHistoryTabContent from "./ActorHistoryTabContent";
import CultureTownsTabContent from "./CultureTownsTabContent";
import CultureTitlesTabContent from "./CultureTitlesTabContent";
import CultureOpinionsTabContent from "./CultureOpinionsTabContent";

function CultureDialog(props) {
  const { culture } = props;

  const tabs = React.useMemo(() => {
    return [
      {
        label: "History",
        content: <ActorHistoryTabContent type="culture" actorID={culture.id} />,
      },
      {
        label: "Info",
        content: <CultureInfoTabContent culture={culture} />,
      },
      {
        label: "Titles",
        content: <CultureTitlesTabContent culture={culture} />,
      },
      {
        label: "Towns",
        content: <CultureTownsTabContent culture={culture} />,
      },
      {
        label: "Diplo",
        content: <CultureOpinionsTabContent culture={culture} />,
      },
    ];
  }, [culture]);

  return (
    <Dialog {...props}>
      <header className="DialogHeader">
        <img
          width="50"
          height="50"
          className="TownDialogHeraldry"
          alt={`${culture.label} Heraldry`}
          src={`http://armoria.herokuapp.com/png/50/${culture.id}?shield=french`}
        />

        <h2 className="DialogHeaderTitle">{culture.label}</h2>
      </header>

      <div className="DialogContent">
        <Tabs tabs={tabs} />
      </div>
    </Dialog>
  );
}

export default React.memo(CultureDialog);

import React from "react";
import Actor from "./Actor";
import AppContext from "./AppContext";

function ActorHistoryTabContent(props) {
  const { actorID, type } = props;

  const { world } = React.useContext(AppContext);

  const actor = React.useMemo(() => {
    return world.chronicles.getActor(type, actorID);
  }, [type, actorID, world]);

  const eventsDOM = React.useMemo(() => {
    return actor.years
      .reduce((eventsDOM, year) => {
        const events = actor
          .getYear(year)
          .map((event) => {
            const description = event.actors.reduce(
              (description, actor, index) => {
                const placeholder = `{${index}}`;

                description.forEach((token, index) => {
                  if (token === placeholder) {
                    description[index] = (
                      <Actor type={actor.type} actorID={actor.id} />
                    );
                  }
                });

                return description;
              },
              event.description.split(" ")
            );

            return (
              <li key={`event-${event.id}`}>
                {description.map((token) => {
                  return typeof token !== "string" ? token : ` ${token} `;
                })}
              </li>
            );
          })
          .reverse();

        eventsDOM.push(
          <div key={`year-${year}`}>
            <h4 className="TabsContentTitle">Year: {year}</h4>
            <ul>{events}</ul>
          </div>
        );

        return eventsDOM;
      }, [])
      .reverse();
  }, [actor]);

  return <div>{eventsDOM}</div>;
}

export default React.memo(ActorHistoryTabContent);

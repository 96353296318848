import { makeRectangle } from "fractal-noise";
import { makeNoise2D } from "open-simplex-noise";

export default function getNoiseFactory(props = {}) {
  const {
    seed,
    width,
    height,
    octaves = 10,
    amplitude = 1,
    frequency = 0.025,
    persistence = 0.5
  } = props;

  const noise = makeRectangle(width, height, makeNoise2D(seed), {
    octaves,
    amplitude,
    frequency,
    persistence
  });

  return function getNoise(x, y) {
    return noise[x][y] + 0.5;
  };
}

import {
  priorities,
  Town as TownActor,
  Culture as CultureActor,
  Alliance as AllianceActor,
  Adventurer as AdventurerActor,
} from "../../chronicles";

import { monarchTitleInfo } from "../culture/monarch-title-creation";

export default function allianceCreation(world) {
  if (world.cultures.length < 2) return;

  if (world.random.bool({ likelihood: 30 }) === false) return;

  const candidates = world.cultures.filter((culture) => {
    return culture.titles.get(monarchTitleInfo.name)?.holder != null;
  });

  if (candidates.length === 0) return;

  const culture = world.random.pickone(candidates);

  let allies = culture.allies;

  const potentialAllies = world.random.shuffle(
    culture.nearByCultures.filter((nearByCulture) => {
      return (
        nearByCulture.titles.get(monarchTitleInfo.name)?.holder != null &&
        allies.includes(nearByCulture) === false
      );
    })
  );

  if (potentialAllies.length === 0) return;

  const [ally] = potentialAllies.filter((potentialAlly) => {
    return world.random.bool({
      likelihood: [
        culture.getOpinion(potentialAlly),
        potentialAlly.getOpinion(culture),
      ].reduce((likelihood, opinion) => {
        return likelihood + (opinion < -10 ? 5 : opinion < 0 ? 20 : 60);
      }, 0),
    });
  });

  if (ally == null) return;

  const duration = world.random.integer({ min: 6, max: 10 });

  const [alliance] = world.createAlliances({
    duration,
    cultureTwo: ally,
    cultureOne: culture,
    cooldown: world.random.integer({ min: 3, max: 6 }),
  });

  if (alliance == null) return;

  const monarch = culture.titles.get(monarchTitleInfo.name).holder;
  const otherMonarch = ally.titles.get(monarchTitleInfo.name).holder;

  const town = world.random.pickone([
    monarch.getMeta("location"),
    otherMonarch.getMeta("location"),
  ]);

  world.addEvents({
    description: `{0} of {1} and {2} of {3} signed the ${duration} year {4} Alliance Pact in {5}`,
    reference: `the ${duration} {6} Alliance Pact signed by {2} of {3} & {4} of {5} in {7}`,
    actors: [
      new AdventurerActor({
        entity: monarch,
      }),
      new CultureActor({
        entity: culture,
      }),
      new AdventurerActor({
        entity: otherMonarch,
      }),
      new CultureActor({
        entity: ally,
      }),
      new AllianceActor({
        entity: alliance,
        importance: priorities.HIGH,
      }),
      new TownActor({
        entity: town,
        importance: priorities.HIGH,
      }),
    ],
  });
}

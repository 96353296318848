import React from "react";

import Tabs from "./Tabs";
import Actor from "./Actor";
import Dialog from "./Dialog";
import ActorHistoryTabContent from "./ActorHistoryTabContent";

function TitleDialog(props) {
  const { title } = props;

  const tabs = React.useMemo(() => {
    return [
      {
        label: "History",
        content: (
          <ActorHistoryTabContent type={title.type} actorID={title.id} />
        ),
      },
    ];
  }, [title]);

  const somethingType = React.useMemo(() => {
    return title.type === "culture-title" ? "culture" : "town";
  }, [title]);

  return (
    <Dialog {...props}>
      <header className="DialogHeader">
        <h2 className="DialogHeaderTitle">
          {title.label} of{" "}
          <Actor type={somethingType} actorID={title._entity.something.id} />
        </h2>
      </header>

      <div className="DialogContent">
        <Tabs tabs={tabs} />
      </div>
    </Dialog>
  );
}

export default React.memo(TitleDialog);

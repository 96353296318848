import {
  priorities,
  Culture as CultureActor,
  Adventurer as AdventurerActor,
  CultureTitle as CultureTitleActor,
} from "../../chronicles";

import { genders } from "culture-generator";

import { monarchTitleInfo } from "./monarch-title-creation";

function getHeir(adventurer) {
  let heir = adventurer.children
    .filter((adventurer) => {
      return adventurer.isAlive(adventurer.culture.world.year);
    })
    .sort((childOne, childTwo) => {
      if (childOne.gender !== childTwo.gender) {
        return childOne.gender === genders.MALE ? -1 : 1;
      }

      return (
        childTwo.getAge(adventurer.culture.world.year) -
        childOne.getAge(adventurer.culture.world.year)
      );
    })[0];

  if (heir == null) {
    heir = adventurer.extendedFamily
      .filter((adventurer) => {
        return adventurer.isAlive(adventurer.culture.world.year);
      })
      .sort((childOne, childTwo) => {
        return (
          childTwo.getAge(adventurer.culture.world.year) -
          childOne.getAge(adventurer.culture.world.year)
        );
      })[0];
  }

  return heir;
}

export default function monarch(culture) {
  const candidates = culture.adventurers.filter(
    (adventurer) =>
      adventurer.getAge(culture.world.year) > 18 &&
      adventurer.getMeta("title") == null
  );

  if (candidates.length === 0) return;

  const monarchTitle = culture.titles.get(monarchTitleInfo.name);
  if (monarchTitle == null) return;

  if (monarchTitle.holder == null) {
    if (culture.random.bool({ likelihood: 40 }) === true) return;

    monarchTitle.holder = culture.random.pickone(candidates);

    culture.world.addEvents({
      description: "{0} is appointed {1} of {2}",
      reference: "the appointment of {2} as the {3} of {4}",
      actors: [
        new AdventurerActor({
          entity: monarchTitle.holder,
          importance: priorities.HIGH,
        }),
        new CultureTitleActor({ entity: monarchTitle }),
        new CultureActor({ entity: culture }),
      ],
    });

    return;
  } else if (monarchTitle.holder.isAlive(culture.world.year) === false) {
    const deadMonarch = monarchTitle.holder;

    culture.titles.all.forEach((title) => {
      const currentHolder = title.holder;

      if (currentHolder == null) return;
      title.holder = null;

      culture.world.addEvents({
        description: "{0} has stepped down from {1} of {2}",
        reference: "the stepping down of {2} as the {3} of {4}",
        actors: [
          new AdventurerActor({
            entity: currentHolder,
            importance: priorities.HIGH,
          }),
          new CultureTitleActor({ entity: title }),
          new CultureActor({ entity: culture }),
        ],
      });
    });

    monarchTitle.holder = getHeir(deadMonarch);

    if (monarchTitle.holder != null) {
      culture.world.addEvents({
        description: "{0} is appointed {1} of {2}",
        reference: "the appointment of {2} as the {3} of {4}",
        actors: [
          new AdventurerActor({
            entity: monarchTitle.holder,
            importance: priorities.HIGH,
          }),
          new CultureTitleActor({ entity: monarchTitle }),
          new CultureActor({ entity: culture }),
        ],
      });
    }

    return;
  }

  const possibleTitles = culture.titles.all.filter(
    (title) => title.holder == null
  );

  const nearbyCandidates = candidates.filter((candidate) => {
    return (
      candidate.getMeta("location") === monarchTitle.holder.getMeta("location")
    );
  });

  if (nearbyCandidates.length === 0) return;

  possibleTitles
    .slice(0, Math.min(4, possibleTitles.length, nearbyCandidates.length))
    .forEach((possibleTitle) => {
      const adventurer = culture.random.pickone(nearbyCandidates);
      possibleTitle.holder = adventurer;

      nearbyCandidates.splice(nearbyCandidates.indexOf(adventurer), 1);

      culture.world.addEvents({
        description: "{0} is appointed {1} of {2}",
        reference: "the appointment of {2} as the {3} of {4}",
        actors: [
          new AdventurerActor({
            entity: possibleTitle.holder,
            importance: priorities.HIGH,
          }),
          new CultureTitleActor({ entity: possibleTitle }),
          new CultureActor({ entity: culture }),
        ],
      });
    });
}

import React from "react";
import Actor from "./Actor";

function AdventurerFamilyTabContent(props) {
  const { adventurer } = props;

  const familyDOM = React.useMemo(() => {
    return [
      {
        name: "Spouse",
        adventurers:
          adventurer.entity.spouse == null ? [] : [adventurer.entity.spouse],
      },
      {
        name: "Children",
        adventurers: adventurer.entity.children,
      },
      {
        name: "Parents",
        adventurers: adventurer.entity.parents,
      },
      {
        name: "Siblings",
        adventurers: adventurer.entity.siblings,
      },
      {
        name: "Grandparents",
        adventurers: adventurer.entity.grandparents,
      },
      {
        name: "Uncle/Aunties",
        adventurers: adventurer.entity.piblings,
      },
      {
        name: "Cousins",
        adventurers: adventurer.entity.cousins,
      },
    ].reduce((familyDOM, info) => {
      familyDOM.push(
        <div key={`family-${info.name}`}>
          <h3 className="TabsContentTitle">{info.name}</h3>

          {info.adventurers.length === 0 ? (
            <center>N/A</center>
          ) : (
            <ul>
              {info.adventurers.map((adventurer) => {
                return (
                  <li key={`adventurer-${adventurer.id}`}>
                    <Actor type="adventurer" actorID={adventurer.id} />
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      );

      return familyDOM;
    }, []);
  }, [adventurer]);

  return familyDOM;
}

export default React.memo(AdventurerFamilyTabContent);

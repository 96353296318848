export default function paintLine(props) {
  const { offset, context, coordinates, color } = props;

  [
    {
      width: 10,
      color: "#222",
    },
    {
      width: 4,
      color: color,
    },
  ].forEach((info) => {
    context.strokeStyle = info.color;
    context.lineWidth = info.width;

    coordinates.forEach((coordinate) => {
      context.beginPath();

      coordinate.forEach((coords) => {
        context.lineTo(coords.x * offset, coords.y * offset - 2);
      });

      context.stroke();
    });
  });
}

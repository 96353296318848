import Title from "./Title";

export default class TitleManager {
  constructor(props) {
    const { world, something } = props;

    this._world = world;
    this._something = something;

    this._id = this.random.guid();
    this._titles = {};
  }

  get world() {
    return this._world;
  }

  get random() {
    return this._world.random;
  }

  get id() {
    return this._id;
  }

  get all() {
    return Object.values(this._titles);
  }

  get something() {
    return this._something;
  }

  get(name) {
    return this._titles[name];
  }

  create(...titlesInfo) {
    return titlesInfo.map((titleInfo) => {
      const { name, label, maleTitle, femaleTitle } = titleInfo;
      if (this._titles[name] != null) {
        throw new Error(`${name} title already exist`);
      }

      const newTitle = new Title({
        name,
        label,
        maleTitle,
        femaleTitle,
        manager: this,
      });

      this._titles[name] = newTitle;

      return newTitle;
    });
  }
}

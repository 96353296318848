import React from "react";

import "./Footer.css";

function Footer() {
  return (
    <>
      <span className="Footer">
        Made with ❤️ by{" "}
        <a href="http://lucatabone.com" rel="noreferrer" target="_blank">
          me
        </a>{" "}
        ^.^/
      </span>
    </>
  );
}

export default React.memo(Footer);

import { Name } from "name-df-generator";

import { TitleManager } from "./titles";
import deathStory from "./stories/town/death";
import inhabitStory from "./stories/town/inhabit";
import childrenStory from "./stories/town/children";
import marriagesStory from "./stories/town/marriages";
import artifactCreationStory from "./stories/town/artifact-creation";
import assignProfessionStory from "./stories/town/assign-profession";
import governorAppointmentStory from "./stories/town/governor-appointment";
import governorTitleCreationStory from "./stories/town/governor-title-creation";

export default class Town {
  constructor(props = {}) {
    const { tile, culture } = props;

    this._culture = culture;
    this._id = this.random.guid();

    this._tile = null;
    this._adventurers = [];
    this._creation = this.world.year;
    this._name = new Name({ seed: this._id });

    this._settings = {
      youths: 15,
    };

    this._titles = new TitleManager({ world: this.world, something: this });

    this.tile = tile;

    this._cache = {
      alive: [],
    };
  }

  get id() {
    return this._id;
  }

  get culture() {
    return this._culture;
  }

  get world() {
    return this._culture.world;
  }

  get random() {
    return this.world.random;
  }

  get tile() {
    return this._tile;
  }

  get x() {
    return this._tile.x;
  }

  get y() {
    return this._tile.y;
  }

  get name() {
    return this._name.toString();
  }

  get creation() {
    return this._creation;
  }

  get adventurers() {
    return [...this._cache.alive];
  }

  get titles() {
    return this._titles;
  }

  set tile(tile) {
    if (this._tile != null) this._tile.removeFeature(this);
    this._tile = tile;
    this._tile.addFeature(this);
  }

  killAdventurers(...adventurers) {
    adventurers.forEach((adventurer) => {
      const position = this._cache.alive.indexOf(adventurer);
      if (position === -1) return;
      this._cache.alive.splice(position, 1);
      adventurer.setMeta("location", null);
    });
  }

  addAdventurers(...adventurers) {
    this._adventurers.push(...adventurers);

    const aliveAdventurers = adventurers.filter((adventurer) =>
      adventurer.isAlive(this.world.year)
    );

    this._cache.alive.push(...aliveAdventurers);

    aliveAdventurers.forEach((adventurer) => {
      adventurer.setMeta("location", this);
    });
  }

  createAdventurers(...adventurersInfo) {
    const newAdventurers = adventurersInfo.map((adventurerInfo = {}) => {
      return this._culture.createAdventurer({
        ...adventurerInfo,
      });
    });

    this.addAdventurers(...newAdventurers);

    return newAdventurers;
  }

  tick() {
    inhabitStory(this);
    childrenStory(this);
    marriagesStory(this);
    deathStory(this);
    assignProfessionStory(this);
    artifactCreationStory(this);
    governorAppointmentStory(this);
    governorTitleCreationStory(this);
  }
}

import {
  Town as TownActor,
  Adventurer as AdventurerActor
} from "../../chronicles";

export default function inhabit(town) {
  if (town.creation + 3 <= town.world.year) return;

  const newAdventurers = town.createAdventurers(
    ...Array(town.random.integer({ min: 9, max: 11 }))
      .fill()
      .map(() => {
        return {
          birth: town.world.year - town.random.integer({ min: 18, max: 26 }),
        };
      })
  );

  newAdventurers.forEach((adventurer) => {
    town.world.addEvents({
      description: "{0} settled in {1}",
      reference: "the settlement of {2} in {3}",
      actors: [
        new AdventurerActor({ entity: adventurer }),
        new TownActor({ entity: town }),
      ],
    });
  });
}

import React from "react";

import "./Dialog.css";

function Dialog(props) {
  const { width = "500px", backgroundImage, onClose: onCloseProp } = props;

  const isCloseable = React.useMemo(() => {
    return typeof onCloseProp === "function";
  }, [onCloseProp]);

  const onClose = React.useCallback(
    (...args) => {
      if (typeof onCloseProp !== "function") return;
      onCloseProp(...args);
    },
    [onCloseProp]
  );

  const stopPropogation = React.useCallback((ev) => {
    ev.stopPropagation();
  }, []);

  React.useEffect(() => {
    const listener = (ev) => {
      if (ev.code === "Escape") onClose();
    };

    window.addEventListener("keydown", listener);

    return () => window.removeEventListener("keydown", listener);
  }, [onClose]);

  const style = React.useMemo(() => {
    return backgroundImage != null
      ? {
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundImage: `url('images/${backgroundImage}')`,
        }
      : {};
  }, [backgroundImage]);

  return (
    <div role="dialog" className="Dialog" onClick={onClose} style={style}>
      <div
        style={{ maxWidth: width }}
        onClick={stopPropogation}
        className="DialogContainer"
      >
        {isCloseable === true && (
          <div
            tabIndex={0}
            role="button"
            onClick={onClose}
            aria-label="Close Dialog"
            className="DialogContainerClose"
          >
            x
          </div>
        )}

        {props.children}
      </div>
    </div>
  );
}

export default React.memo(Dialog);

import { priorities, Culture as CultureActor } from "../../chronicles";

export default function worldCultureCreation(world) {
  if (world.cultures.length > 4) return;

  world
    .createCultures(
      ...Array(
        world.random.integer({
          min: 0,
          max: 2,
        })
      )
        .fill()
        .map(() => ({
          seed: world.random.guid(),
        }))
    )
    .forEach((newCulture) => {
      world.addEvents({
        description: "The {0} civilization started writing history",
        reference: "the start of history for the {2} civilization",
        actors: [
          new CultureActor({
            entity: newCulture,
            importance: priorities.HIGH,
          }),
        ],
      });
    });
}

import React from "react";
import Actor from "./Actor";

function CultureTitlesTabContent(props) {
  const { culture: cultureProp } = props;

  const culture = React.useMemo(() => {
    return cultureProp.entity;
  }, [cultureProp]);

  return React.useMemo(() => {
    return culture.titles.all.map((title) => {
      return (
        <div key={`key-${title.name}`}>
          <h4 className="TabsContentTitle">
            <Actor type="culture-title" actorID={title.id} />
          </h4>
          <p>
            {title.holder == null ? (
              <center>N/A</center>
            ) : (
              <Actor type="adventurer" actorID={title.holder.id} />
            )}
          </p>
        </div>
      );
    });
  }, [culture]);
}

export default React.memo(CultureTitlesTabContent);

import React from "react";
import Actor from "./Actor";

function CultureOpinionsTabContent(props) {
  const { culture: cultureProp } = props;

  const culture = React.useMemo(() => {
    return cultureProp.entity;
  }, [cultureProp]);

  const opinionDOM = React.useMemo(() => {
    const allies = culture.allies;

    return culture.nearByCultures.map((otherCulture) => {
      return (
        <li key={`culture-${otherCulture.id}`}>
          <Actor type="culture" actorID={otherCulture.id} />:{" "}
          {allies.includes(otherCulture) === true
            ? "Allies"
            : culture.getOpinion(otherCulture)}
        </li>
      );
    });
  }, [culture]);

  return <ul>{opinionDOM}</ul>;
}

export default React.memo(CultureOpinionsTabContent);

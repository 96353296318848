import Title from "./Title";

export default class CultureTitle extends Title {
  constructor(props) {
    super({
      ...props,
      type: "culture-title",
    });
  }
}

import React from "react";

import { World } from "./lib";

import TownDialog from "./TownDialog";
import SimulationTime from "./SimulationTime";

import AppContext from "./AppContext";
import TitleDialog from "./TitleDialog";
import CultureDialog from "./CultureDialog";
import AdventurerDialog from "./AdventurerDialog";

import "./Simulation.css";

export default function Simulation(props) {
  const { seed, width, height, onReset } = props;

  const canvasRef = React.useRef();
  const [world, setWorld] = React.useState();
  const [selectedActorInfo, setSelectedActorInfo] = React.useState();

  React.useLayoutEffect(() => {
    const world = new World({
      seed,
      canvas: canvasRef.current,
      onTownSelection: (entity) => {
        setSelectedActorInfo({
          entity,
          type: "town",
        });
      },
    });

    window.world = world;
    window.tick = function tick() {
      world.tick();
      world.draw();
    };

    setWorld(world);
  }, [seed]);

  const onDialogClose = React.useCallback(() => {
    setSelectedActorInfo(null);
  }, []);

  const dialogDOM = React.useMemo(() => {
    if (selectedActorInfo == null) return;

    const { type, entity } = selectedActorInfo;

    switch (type) {
      case "town": {
        return <TownDialog town={entity} onClose={onDialogClose} />;
      }
      case "adventurer": {
        return <AdventurerDialog adventurer={entity} onClose={onDialogClose} />;
      }
      case "culture": {
        return <CultureDialog culture={entity} onClose={onDialogClose} />;
      }
      case "town-title":
      case "culture-title": {
        return <TitleDialog title={entity} onClose={onDialogClose} />;
      }
      default:
        return null;
    }
  }, [selectedActorInfo, onDialogClose]);

  return (
    <div className="Simulation">
      <AppContext.Provider
        value={{
          world,
          selectedActorInfo,
          setSelectedActorInfo,
        }}
      >
        <SimulationTime onReset={onReset} />

        {dialogDOM}

        <div className="SimulationMap">
          <canvas
            className="SimulationMapCanvas"
            ref={canvasRef}
            width={width}
            height={height}
          ></canvas>
        </div>
      </AppContext.Provider>
    </div>
  );
}

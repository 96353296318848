import React from "react";

import Dialog from "./Dialog";

import "./SettingsDialog.css";

function SettingsDialog(props) {
  const { onSubmit: onSubmitProp } = props;

  const [seed, setSeed] = React.useState(Math.floor(Math.random() * 55555));

  const [width, setWidth] = React.useState(
    String(Math.floor(window.innerWidth) - 10)
  );

  const [height, setHeight] = React.useState(
    String(Math.floor(window.innerHeight) - 10)
  );

  const seedFieldID = React.useId();
  const widthFieldID = React.useId();
  const heightFieldID = React.useId();

  const onSeedChange = React.useCallback((ev) => {
    setSeed(ev.target.value);
  }, []);

  const onWidthChange = React.useCallback((ev) => {
    setWidth(ev.target.value);
  }, []);

  const onHeightChange = React.useCallback((ev) => {
    setHeight(ev.target.value);
  }, []);

  const isSubmittable = React.useMemo(() => {
    return seed !== "" && width !== "" && height !== "";
  }, [seed, width, height]);

  const onSubmit = React.useCallback(() => {
    onSubmitProp({
      seed,
      width,
      height,
    });
  }, [seed, width, height, onSubmitProp]);

  return (
    <Dialog width="300px" backgroundImage="background.png" {...props}>
      <header>
        <h2 className="DialogHeaderTitle">
          <center>Nociland</center>
        </h2>
      </header>

      <div className="DialogContent SettingsDialogContent">
        <div>
          <label htmlFor={seedFieldID}>Seed:</label>
          <input
            type="text"
            id={seedFieldID}
            value={seed}
            onChange={onSeedChange}
          />
        </div>

        <div>
          <label htmlFor={widthFieldID}>Width:</label>
          <input
            min={1}
            type="number"
            id={widthFieldID}
            value={width}
            onChange={onWidthChange}
          />
        </div>

        <div>
          <label htmlFor={heightFieldID}>Height:</label>
          <input
            min={1}
            type="number"
            id={heightFieldID}
            value={height}
            onChange={onHeightChange}
          />
        </div>

        <button onClick={onSubmit} disabled={isSubmittable === false}>
          Create
        </button>
      </div>
    </Dialog>
  );
}

export default React.memo(SettingsDialog);

export const priorities = {
  HIGH: 2,
  MEDIUM: 1,
  LOW: 0,
};

export default class Event {
  constructor(props) {
    const { type, entity, importance = priorities.LOW } = props;

    this._type = type;
    this._events = {};
    this._entity = entity;
    this._importance = importance;
  }

  get type() {
    return this._type;
  }

  get entity() {
    return this._entity;
  }

  get id() {
    return this._entity.id;
  }

  get label() {
    return this._entity.name;
  }

  get years() {
    return Object.keys(this._events);
  }

  get events() {
    return Object.values(this._events).reduce((events, yearEvents) => {
      return [...events, ...Object.values(yearEvents)];
    }, []);
  }

  get importance() {
    return this._importance;
  }

  set importance(priority) {
    this._importance = priority;
  }

  getYear(year) {
    const events = this._events[year];
    return events == null ? [] : [...events];
  }

  add(event) {
    let events = this._events[event.year];
    if (events == null) events = this._events[event.year] = [];
    events.push(event);
  }
}

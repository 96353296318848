import {
  Town as TownActor,
  Adventurer as AdventurerActor,
} from "../../chronicles";

import { genders } from "culture-generator";

export default function marriages(town) {
  const adventurers = town.adventurers;

  const { males, females } = adventurers.reduce(
    (info, adventurer) => {
      if (
        adventurer.spouse != null &&
        adventurer.spouse.isAlive(town.world.year) === true
      )
        return info;
      if (adventurer.getAge(town.world.year) < 18) return info;

      info[adventurer.gender === genders.MALE ? "males" : "females"].push(
        adventurer
      );

      return info;
    },
    {
      males: [],
      females: [],
    }
  );

  const spouses = males.length > females.length ? males : females;
  const possibleSpouses = spouses === females ? males : females;

  const amount = town.random.integer({
    min: 0,
    max: Math.ceil(spouses.length / 2),
  });

  town.random
    .shuffle(spouses)
    .slice(0, amount)
    .forEach((spouse) => {
      let otherSpouse = town.random
        .shuffle(possibleSpouses)
        .find((possibleSpouse) => {
          return (
            spouse.extendedFamily.includes(possibleSpouse) === false &&
            possibleSpouse.extendedFamily.includes(spouse) === false
          );
        });

      if (otherSpouse == null) {
        [otherSpouse] = town.createAdventurers({
          gender:
            spouse.gender !== genders.MALE ? genders.MALE : genders.FEMALE,
          birth:
            town.world.year -
            town.random.integer({
              min: 18,
              max: 26,
            }),
        });
      }

      const otherSpousePosition = possibleSpouses.indexOf(otherSpouse);

      if (otherSpousePosition !== -1) {
        possibleSpouses.splice(otherSpousePosition, 1);
      }

      spouse.spouse = otherSpouse;

      town.world.addEvents({
        description: "{0} married {1} in {2}",
        reference: "the marriage between {2} in {3} in {4}",
        actors: [
          new AdventurerActor({ entity: spouse }),
          new AdventurerActor({ entity: otherSpouse }),
          new TownActor({ entity: town }),
        ],
      });
    });
}

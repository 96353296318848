import {
  priorities,
  Town as TownActor,
  Road as RoadActor,
  Culture as CultureActor,
} from "../../chronicles";

import weightedPickone from "../../utils/weightedPickone";

export default function newSettlement(culture) {
  if (culture.random.bool({ likelihood: 80 }) === true) return;

  for (let attempt = 0; attempt < 4; attempt++) {
    const town = weightedPickone(culture.towns, culture.random, 4);

    const size = 4;
    const distance = Math.floor(size / 2);

    const potentialTile = culture.world.tiles.getHabitableTile({
      fromX: Math.max(0, town.x - size),
      fromY: Math.max(0, town.y - size),
      toX: Math.min(culture.world.width, town.x + size),
      toY: Math.min(culture.world.height, town.y + size),
    });

    if (potentialTile.nearBy({ distance, types: "TOWN" }).length > 0) continue;

    const [newTown] = culture.createTowns({
      tile: potentialTile,
      culture,
    });

    culture.world.addEvents({
      description: "{0} , a {1} settlment, has been founded by people from {2}",
      reference:
        "the foundation of {2} , a {3} settlement, by the people of {4}",
      actors: [
        new TownActor({ entity: newTown, priorities: priorities.MEDIUM }),
        new CultureActor({ entity: culture }),
        new TownActor({ entity: town }),
      ],
    });

    const newRoad = culture.world.createRoad(town, newTown);

    if (newRoad != null) {
      culture.world.addEvents({
        description: "{0} has been constructed between {1} and {2}",
        reference: "the construction of {2} connecting {3} and {4}",
        actors: [
          new RoadActor({ entity: newRoad }),
          new TownActor({ entity: town }),
          new TownActor({ entity: newTown }),
        ],
      });
    }

    break;
  }
}

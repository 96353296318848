import Actor from "./Actor";

export default class Culture extends Actor {
  constructor(props) {
    super({
      ...props,
      type: "culture",
    });
  }
}

import React from "react";
import AppContext from "./AppContext";

import "./SimulationTime.css";

function SimulationTime(props) {
  const { onReset } = props;
  const { world } = React.useContext(AppContext);

  const [isPlaying, setPlaying] = React.useState(false);
  const [isInitialized, setInitialized] = React.useState(false);

  const [year, setYear] = React.useState();

  React.useEffect(() => {
    window.play = isPlaying;
  }, [isPlaying]);

  const name = React.useMemo(() => {
    return world == null ? "" : world.name;
  }, [world]);

  const tick = React.useCallback(() => {
    return new Promise((resolve) => {
      if (world == null) return resolve();

      window.setTimeout(() => {
        world.tick();
        setYear(world.year);
        world.draw();
        resolve();
      }, 0);
    });
  }, [world]);

  React.useEffect(() => {
    setInitialized(false);
    tick().then(() => setInitialized(true));
    return () => setInitialized(false);
  }, [tick]);

  const play = React.useCallback(
    async (years = 100) => {
      setPlaying(true);
      window.play = true;

      let previousPromise = Promise.resolve();

      for (let index = 0; index < years; index++) {
        if (window.play === false) break;
        await previousPromise;
        previousPromise = tick();
      }
    },
    [tick]
  );

  const stop = React.useCallback(() => {
    setPlaying(false);
  }, []);

  return (
    <div className="SimulationTime">
      {isInitialized === true && (
        <button className="SimulationTimeButton" onClick={onReset}>
          ⚙
        </button>
      )}

      <div className="SimulationTimeDetails">
        {isInitialized === false ? (
          "Initializing"
        ) : (
          <>
            <strong>{name}</strong>
            <div>Year: {year}</div>
          </>
        )}
      </div>

      {isInitialized === true && (
        <button
          className="SimulationTimeButton"
          aria-label={isPlaying === true ? "Stop" : "Start"}
          onClick={() => (isPlaying === true ? stop() : play())}
        >
          {isPlaying === true ? "■" : "►"}
        </button>
      )}
    </div>
  );
}

export default React.memo(SimulationTime);

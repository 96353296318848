import { genders } from "culture-generator";

import Actor from "./Actor";

export default class Adventurer extends Actor {
  constructor(props) {
    super({
      ...props,
      type: "adventurer",
    });
  }

  get label() {
    const title = this._entity.getMeta("title");

    return title == null
      ? this._entity.fullName
      : `${
          this._entity.gender === genders.MALE
            ? title.maleTitle
            : title.femaleTitle
        } ${this._entity.fullName}`;
  }
}

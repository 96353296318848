import {
  priorities,
  Town as TownActor,
  Culture as CultureActor,
  Alliance as AllianceActor,
  Adventurer as AdventurerActor,
} from "../../chronicles";

import { monarchTitleInfo } from "../culture/monarch-title-creation";

export default function allianceCreation(world) {
  world.alliances.forEach((alliance) => {
    if (alliance.isActive === true) return;

    if (alliance.isExpired === true) {
      world.removeAlliances(alliance);

      world.addEvents({
        description:
          "Discussions between {0} & {1} civilizations to renew the {2} Alliance Pact failed",
        reference:
          "the disagreement between {2} & {3} civilizations to renew the {4} Alliance Pact",
        actors: [
          new CultureActor({
            entity: alliance.cultureOne,
          }),
          new CultureActor({
            entity: alliance.cultureTwo,
          }),
          new AllianceActor({
            entity: alliance,
            importance: priorities.HIGH,
          }),
        ],
      });

      return;
    }

    if (alliance.isEndYear === true) {
      world.addEvents({
        description:
          "{0} & {1} civilizations started discussions to renew the {2} Alliance Pact",
        reference:
          "the start of discussions to renew the {4} Alliance Pact between {2} & {3}",
        actors: [
          new CultureActor({
            entity: alliance.cultureOne,
          }),
          new CultureActor({
            entity: alliance.cultureTwo,
          }),
          new AllianceActor({
            entity: alliance,
            importance: priorities.HIGH,
          }),
        ],
      });

      return;
    }

    const [cultureOne, cultureTwo] = alliance.cultures;

    const monarchs = [cultureOne, cultureTwo].map((culture) => {
      return culture.titles.get(monarchTitleInfo.name).holder;
    });

    if (monarchs.includes(null) === true) return;
    if (world.random.bool({ likelihood: 10 }) === false) return;

    const duration = world.random.integer({ min: 7, max: 10 });
    alliance.extend(duration);

    const town = world.random.pickone(
      monarchs.map((monarch) => monarch.getMeta("location"))
    );

    world.addEvents({
      description: `{0} of {1} & {2} of {3} agreed to extend the {4} Alliance Pact by ${duration} years in {5}`,
      reference: `the agreement between {2} of {3} & {4} of {5} to extend the {6} Alliance Pact by ${duration} years {7}`,
      actors: [
        new AdventurerActor({
          entity: monarchs[0],
        }),
        new CultureActor({
          entity: cultureOne,
        }),
        new AdventurerActor({
          entity: monarchs[1],
        }),
        new CultureActor({
          entity: cultureTwo,
        }),
        new AllianceActor({
          entity: alliance,
          importance: priorities.HIGH,
        }),
        new TownActor({
          entity: town,
          importance: priorities.HIGH,
        }),
      ],
    });
  });
}

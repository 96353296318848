import ChronicleEvent from "./ChronicleEvent";

export default class Chronicle {
  constructor(props = {}) {
    const { world } = props;

    this._world = world;
    this._id = this.random.guid();

    this._events = {};
    this._actors = {};
  }

  get world() {
    return this._world;
  }

  get random() {
    return this._world.random;
  }

  get events() {
    return { ...this._events };
  }

  get years() {
    return Object.keys(this._events);
  }

  get actors() {
    return Object.values(this._actors).reduce((actors, typeActors) => {
      return [...actors, ...Object.values(typeActors)];
    }, []);
  }

  getActor(type, id) {
    return this._actors[type] == null ? null : this._actors[type][id];
  }

  registerActors(...actors) {
    return actors.map((actor) => {
      let type = this._actors[actor.type];
      if (type == null) type = this._actors[actor.type] = {};
      if (type[actor.id] == null) type[actor.id] = actor;
      type[actor.id].importance = Math.max(
        type[actor.id].importance,
        actor.importance
      );
      return type[actor.id];
    });
  }

  getYear(year) {
    const events = this._events[year];
    return events == null ? [] : [...events];
  }

  add(...eventsInfo) {
    eventsInfo.forEach((eventInfo) => {
      const { year } = eventInfo;

      let events = this._events[year];
      if (events == null) events = this._events[year] = [];

      const event = new ChronicleEvent({
        ...eventInfo,
        chronicle: this,
      });

      events.push(event);
    });
  }
}

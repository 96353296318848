import Actor from "./Actor";

export default class Road extends Actor {
  constructor(props) {
    super({
      ...props,
      type: "road",
    });
  }
}

import {
  Town as TownActor,
  Culture as CultureActor,
  priorities,
} from "../../chronicles";

export default function cultureTownCreation(culture) {
  if (culture.towns.length > 0) return;

  const [newTown] = culture.createTowns({
    culture,
  });

  culture.world.addEvents({
    description: "The people of {0} founded {1}",
    reference: "the foundation of {3} by the people of {2}",
    actors: [
      new CultureActor({ entity: culture }),
      new TownActor({ entity: newTown, priorities: priorities.HIGH }),
    ],
  });
}

import getNoiseFactory from "./getNoiseFactory";

export default function getLinearNoiseFactory(props) {
  const { seed, size, width, height, position } = props;

  const getNoise = getNoiseFactory({
    seed,
    width,
    height
  });

  return function getLinearNoise(x, y) {
    const lowerLimit = position - size;
    const higherLimit = position + size;

    if (y >= lowerLimit && y <= higherLimit) return 1;

    return (
      (y < lowerLimit
        ? y / lowerLimit
        : 1 - (y - higherLimit) / (height - higherLimit)) -
      getNoise(x, y) / 2
    );
  };
}

import {
  Town as TownActor,
  Adventurer as AdventurerActor,
} from "../../chronicles";

import weightedPickone from "../../utils/weightedPickone";

const professions = ["Musician", "Poet", "Painter", "Sculptor", "Writer"];

export default function artifactCreation(town) {
  const professionals = town.adventurers.filter((adventurer) => {
    return professions.includes(adventurer.getMeta("profession")) === true;
  });

  if (professionals.length === 0) return;

  if (town.random.bool({ likelihood: 70 }) === true) return;

  const adventurer = town.random.pickone(professionals);

  const profession = adventurer.getMeta("profession");

  const actor = weightedPickone(
    town.world.chronicles.actors.sort((actorOne, actorTwo) => {
      return actorTwo.importance - actorOne.importance;
    }),
    town.random,
    3
  );

  const event = town.random.pickone(actor.events);

  const verb =
    profession === "Musician"
      ? "composed a song"
      : profession === "Poet"
      ? "wrote a poem"
      : profession === "Painter"
      ? "drew a painting"
      : profession === "Sculptor"
      ? "sulpted a sculpture"
      : "wrote a novel";

  town.world.addEvents({
    description: `{0} ${verb} in {1} about ${event.reference}`,
    reference: `{0} becoming a ${profession} in {1}`,
    actors: [
      new AdventurerActor({ entity: adventurer }),
      new TownActor({ entity: town }),
      ...event.actors,
    ],
  });
}

export function generateID(...cultures) {
  return cultures
    .map((culture) => culture.id)
    .sort()
    .join(":");
}

export default class Alliance {
  constructor(props) {
    const { world, cooldown, duration, cultureOne, cultureTwo } = props;

    if (cultureOne == null || cultureTwo == null) {
      throw new Error("Alliances must happen between two cultures");
    }

    if (cultureOne === cultureTwo) {
      throw new Error("Alliances need to happen between different cultures");
    }

    this._id = generateID(cultureOne, cultureTwo);
    this._world = world;
    this._year = world.year;
    this._cooldown = cooldown;
    this._duration = duration;
    this._cultureOne = cultureOne;
    this._cultureTwo = cultureTwo;
  }

  get id() {
    return this._id;
  }

  get name() {
    return this.cultures.map((culture) => culture.name).join(" - ");
  }

  get year() {
    return this._year;
  }

  get world() {
    return this._world;
  }

  get cooldown() {
    return this._cooldown;
  }

  get duration() {
    return this._duration;
  }

  get cultureOne() {
    return this._cultureOne;
  }

  get cultureTwo() {
    return this._cultureTwo;
  }

  get cultures() {
    return [this._cultureOne, this._cultureTwo];
  }

  get endYear() {
    return this._year + this._duration;
  }

  get isEndYear() {
    return this._world.year === this.endYear;
  }

  get isActive() {
    return this._world.year < this.endYear;
  }

  get isExpired() {
    return this._world.year >= this.endYear + this._cooldown;
  }

  extend(years = 10) {
    this._duration += this._world.year - this.endYear + years;
  }
}

import {
  Town as TownActor,
  Adventurer as AdventurerActor,
} from "../../chronicles";

import { genders } from "culture-generator";

export default function children(town) {
  const { wifes, youths } = town.adventurers.reduce(
    (info, adventurer) => {
      if (adventurer.getAge(town.world.year) < 35) {
        info.youths += 1;
      }

      if (adventurer.spouse == null) return info;
      if (adventurer.gender !== genders.FEMALE) return info;

      if (
        adventurer.getMeta("location") !== adventurer.spouse.getMeta("location")
      )
        return info;

      const lastBirth = Math.min(
        ...adventurer.children.map((child) => child.getAge(town.world.year))
      );

      if (lastBirth > 2) info.wifes.push(adventurer);

      return info;
    },
    {
      wifes: [],
      youths: 0,
    }
  );

  wifes.sort((wifeOne, wifeTwo) => {
    return wifeOne.children.length - wifeTwo.children.length;
  });

  const amount = town.random.integer({
    min: 0,
    max: Math.max(0, Math.min(wifes.length, town._settings.youths - youths)),
  });

  wifes.slice(0, amount).forEach((wife) => {
    const husband = wife.spouse;
    const child = wife.reproduce(husband, { birth: town.world.year });

    town.addAdventurers(child);

    child.setMeta("location", town);

    town.world.addEvents({
      description: "{0} and {1} had a child who they named {2} in {3}",
      reference: "the birth of {4} by {2} and {3} in {5}",
      actors: [
        new AdventurerActor({ entity: husband }),
        new AdventurerActor({ entity: wife }),
        new AdventurerActor({ entity: child }),
        new TownActor({ entity: town }),
      ],
    });
  });
}

import React from "react";

function CultureInfoTabContent(props) {
  const { culture: cultureProp } = props;

  const culture = React.useMemo(() => {
    return cultureProp.entity;
  }, [cultureProp]);

  return (
    <>
      <div>
        <h3 className="TabsContentTitle">Ethics</h3>
        <p>{culture.ethics.toString()}</p>
      </div>
      <div>
        <h3 className="TabsContentTitle">Beliefs</h3>
        <p>{culture.beliefs.toString()}</p>
      </div>
    </>
  );
}

export default React.memo(CultureInfoTabContent);

import React from "react";
import { genders } from "culture-generator";
import Actor from "./Actor";

function TownNoblesTabContent(props) {
  const { town } = props;

  const adventurers = React.useMemo(() => {
    return town.entity.adventurers.map((adventurer) => {
      return (
        <li key={`noble-${adventurer.id}`}>
          <Actor type="adventurer" actorID={adventurer.id} />
          {adventurer.getAge(town.entity.world.year) < 18 && " ☺ "}
          {adventurer.gender === genders.MALE ? " ♂ " : " ♀ "}
          {adventurer.getAge(town.entity.world.year)}
        </li>
      );
    });
  }, [town]);

  return <ul>{adventurers}</ul>;
}

export default React.memo(TownNoblesTabContent);

import Tile from "./Tile";
import tileTypes from "./tileTypes";
import getNoiseFactory from "./getNoiseFactory";
import getLinearNoiseFactory from "./getLinearNoiseFactory";

export default class TileManager {
  constructor(props) {
    const { world } = props;

    this._world = world;

    this._cache = {
      tiles: {},
      features: [],
    };

    this.getSandNoise = getNoiseFactory({
      width: this.width,
      height: this.height,
      seed: this.random.integer(),
    });

    this.getRainfallNoise = getNoiseFactory({
      width: this.width,
      height: this.height,
      seed: this.random.integer(),
    });

    this.getElevationNoise = getNoiseFactory({
      width: this.width,
      height: this.height,
      seed: this.random.integer(),
    });

    this.getVegetationNoise = getNoiseFactory({
      width: this.width,
      height: this.height,
      seed: this.random.integer(),
    });

    this.getTemperatureNoise = getLinearNoiseFactory({
      width: this.width,
      height: this.height,
      seed: this.random.integer(),
      size: Math.floor(this.height * 0.4),
      position: this.random.integer({
        min: Math.floor(this.height * 0.4),
        max: Math.floor(this.height * 0.6),
      }),
    });
  }

  get world() {
    return this._world;
  }

  get random() {
    return this._world.random;
  }

  get width() {
    return this._world.width;
  }

  get height() {
    return this._world.height;
  }

  get features() {
    return [...this._cache.features];
  }

  get(x, y) {
    const id = `${x}:${y}`;
    if (this._cache.tiles[id] != null) return this._cache.tiles[id];

    this._cache.tiles[id] = new Tile({
      x,
      y,
      manager: this,
      sand: this.getSandNoise(x, y),
      rainfall: this.getRainfallNoise(x, y),
      elevation: this.getElevationNoise(x, y),
      vegetation: this.getVegetationNoise(x, y),
      temperature: this.getTemperatureNoise(x, y),
    });

    return this._cache.tiles[id];
  }

  getHabitableTile(info = {}) {
    const fromX = info.fromX == null ? 0 : Math.max(0, info.fromX);
    const fromY = info.fromY == null ? 0 : Math.max(0, info.fromY);
    const toX =
      info.toX == null ? this.width - 1 : Math.min(this.width - 1, info.toX);
    const toY =
      info.toY == null ? this.height - 1 : Math.min(this.height - 1, info.toY);

    let attempts = 0;

    while (attempts < 10) {
      const x = this.random.integer({
        min: fromX,
        max: toX,
      });

      const y = this.random.integer({
        min: fromY,
        max: toY,
      });

      const tile = this.get(x, y);

      if (tile.town != null) continue;

      if (
        [
          tileTypes.WATER,
          tileTypes.OCEAN,
          tileTypes.OCEAN_ICE,
          tileTypes.WATER_ICE,
          tileTypes.MOUNTAIN_ICE,
        ].includes(tile.type) === true
      )
        continue;

      return tile;
    }

    return null;
  }

  cacheFeature(tile) {
    if (this._cache.features.includes(tile) === true) return;
    this._cache.features.push(tile);
  }

  uncacheFeature(tile) {
    const position = this._cache.features.indexOf(tile);
    if (position === -1) return;
    this._cache.features.splice(position, 1);
  }
}

import React from "react";

import "./Tabs.css";

function Tabs(props) {
  const { tabs = [] } = props;

  const [selectedTab, setSelectedTab] = React.useState(0);

  React.useEffect(() => {
    setSelectedTab(0)
  }, [tabs])

  const tabItems = React.useMemo(() => {
    return tabs.map((tab, index) => {
      const classes = ["TabsNavigationItem"];

      if (index === selectedTab) classes.push("TabsNavigationItem--Active");

      return (
        <li
          tabIndex={0}
          role="button"
          key={`tab-${tab.label}`}
          className={classes.join(" ")}
          onClick={() => setSelectedTab(index)}
        >
          {tab.label}
        </li>
      );
    });
  }, [tabs, selectedTab]);

  const content = React.useMemo(() => {
    return tabs[selectedTab]?.content;
  }, [tabs, selectedTab]);

  return (
    <div className="Tabs">
      <nav>
        <ul className="TabsNavigation">{tabItems}</ul>
      </nav>

      <div className="TabsContent">{content}</div>
    </div>
  );
}

export default React.memo(Tabs);

import newMap from "binos";
import Chance from "chance";
import { Name } from "name-df-generator";

import Road from "./Road";
import Culture from "./Culture";
import { default as Alliance, generateID } from "./Alliance";
import { TileManager } from "./tiles";
import { Chronicle } from "./chronicles";
import paintLine from "./utils/paintLine";

import cultureCreationStory from "./stories/world/culture-creation";
import allianceCreationStory from "./stories/world/alliance-creation";
import allianceTerminationStory from "./stories/world/alliance-termination";

export default class World {
  constructor(props = {}) {
    const { seed, canvas, offset = 15, onTownSelection } = props;

    this._random = new Chance(seed);
    this._id = this._random.guid();

    this._offset = offset;
    this._canvas = canvas;
    this._onTownSelection = onTownSelection;

    this._roads = [];
    this._year = 1000;
    this._cultures = [];
    this._alliances = [];

    this._name = new Name({ seed: this._id });
    this._chronicles = new Chronicle({ world: this });

    this._context = setupContext(canvas, (x, y) => this.handleClick(x, y));

    this._tiles = new TileManager({
      world: this,
    });
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name.toString();
  }

  get random() {
    return this._random;
  }

  get cultures() {
    return [...this._cultures];
  }

  get alliances() {
    return [...this._alliances];
  }

  get roads() {
    return [...this._roads];
  }

  get width() {
    return Math.floor(this._canvas.width / this._offset);
  }

  get height() {
    return Math.floor(this._canvas.height / this._offset);
  }

  get year() {
    return this._year;
  }

  get chronicles() {
    return this._chronicles;
  }

  get tiles() {
    return this._tiles;
  }

  handleClick(xProp, yProp) {
    const x = Math.floor(xProp / this._offset);
    const y = Math.floor(yProp / this._offset);

    const chosenFeature = this._tiles.features.reduce(
      (chosenFeature, feature) => {
        const distance = feature.getDistance(x, y);

        if (distance > 1) return chosenFeature;
        if (feature.isTown === false) return chosenFeature;

        if (chosenFeature == null) return feature;

        return chosenFeature.getDistance(x, y) > feature.getDistance(x, y)
          ? feature
          : chosenFeature;
      },
      null
    );

    if (chosenFeature == null) return;

    this._onTownSelection(
      this._chronicles.getActor("town", chosenFeature.town.id)
    );
  }

  addEvents(...eventInfo) {
    this._chronicles.add(
      ...eventInfo.map((eventInfo) => {
        return { ...eventInfo, year: this._year };
      })
    );
  }

  createRoad(source, destination) {
    const xTo = Math.max(source.x, destination.x);
    const xFrom = Math.min(source.x, destination.x);
    const yTo = Math.max(source.y, destination.y);
    const yFrom = Math.min(source.y, destination.y);

    const surroundingTiles = [];

    for (let y = yFrom; y <= yTo; y++) {
      for (let x = xFrom; x <= xTo; x++) {
        surroundingTiles.push(this._tiles.get(x, y));
      }
    }

    const coordinates = newMap(surroundingTiles)(source, destination, {
      diagonals: false,
      calculateG: (nodeOne, nodeTwo) => {
        const distance = Math.sqrt(
          Math.pow(nodeOne.x - nodeTwo.x, 2) +
            Math.pow(nodeOne.y - nodeTwo.y, 2)
        );

        const tileOne = this._tiles.get(nodeOne.x, nodeOne.y);
        const tileTwo = this._tiles.get(nodeTwo.x, nodeTwo.y);

        const elevation = Math.abs(tileOne.elevation - tileTwo.elevation);

        return distance + elevation;
      },
    }).reverse();

    if (coordinates.length === 0) return;

    const newRoad = new Road({
      world: this,
      tiles: coordinates.map((coordinate) =>
        this._tiles.get(coordinate.x, coordinate.y)
      ),
    });

    this._roads.push(newRoad);

    return newRoad;
  }

  createCultures(...culturesInfo) {
    const newCulture = culturesInfo.map((cultureInfo = {}) => {
      const newCulture = new Culture({
        ...cultureInfo,
        world: this,
      });

      return newCulture;
    });

    this._cultures.push(...newCulture);

    return newCulture;
  }

  createAlliances(...alliancesInfo) {
    const allianceIDs = this._alliances.map((alliance) => alliance.id);

    const newAlliances = alliancesInfo.reduce((newAlliances, allianceInfo) => {
      if (
        allianceIDs.includes(
          generateID(allianceInfo.cultureOne, allianceInfo.cultureTwo)
        ) === true
      ) {
        return newAlliances;
      }

      newAlliances.push(
        new Alliance({
          ...allianceInfo,
          world: this,
        })
      );

      return newAlliances;
    }, []);

    this._alliances.push(...newAlliances);

    return newAlliances;
  }

  removeAlliances(...alliances) {
    alliances.forEach((alliance) => {
      const position = this._alliances.indexOf(alliance);
      if (position === -1) return;
      this._alliances.splice(position, 1);
    });
  }

  clear() {
    this._context.clearRect(
      0,
      0,
      this.width * this._offset,
      this.height * this._offset
    );
  }

  draw() {
    for (let y = 0; y < this.height; y++) {
      for (let x = 0; x < this.width; x++) {
        const tile = this._tiles.get(x, y);

        const positionX = x * this._offset;
        const positionY = y * this._offset;

        const ui = tile.ui;

        this._context.fillStyle = ui.backgroundColor;

        this._context.fillRect(positionX - 8, positionY - 9, 20, 20);

        this._context.fillStyle = ui.color;
        this._context.fillText(ui.icon, positionX, positionY);
      }
    }

    paintLine({
      color: "#978169",
      offset: this._offset,
      context: this._context,
      coordinates: this._roads.map((road) => road.tiles),
    });

    this._tiles.features.forEach((feature) => {
      if (feature.isTown === false) return;

      const positionX = feature.x * this._offset;
      const positionY = feature.y * this._offset;

      const ui = feature.ui;

      this._context.fillStyle = ui.backgroundColor;

      this._context.fillRect(positionX - 8, positionY - 9, 20, 20);

      this._context.fillStyle = ui.color;
      this._context.fillText(ui.icon, positionX, positionY);
    });
  }

  tick() {
    this._year += 1;

    cultureCreationStory(this);
    allianceCreationStory(this);
    allianceTerminationStory(this);

    this._cultures.forEach((culture) => {
      culture.tick();
    });
  }
}

function setupContext(canvas, onClick) {
  const context = canvas.getContext("2d");

  context.textAlign = "center";
  context.font = "10px monospace";
  context.lineCap = "round";
  context.lineJoin = "round";

  function getCursorPosition(ev) {
    const rect = canvas.getBoundingClientRect();
    const x = ev.clientX - rect.left;
    const y = ev.clientY - rect.top;
    onClick(x, y);
  }

  canvas.addEventListener("click", function (e) {
    getCursorPosition(e);
  });

  return context;
}
